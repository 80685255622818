import { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Td, Th, Tr } from 'common/materials';
import { TableContainer, Badge, Tag, Kbd } from '@chakra-ui/react';

import { useStore, useLoader } from 'common/hooks';
import { Button, Input, Label } from 'common/materials';
import { UserCompany } from 'models/user/UserCompany';
import { IoRefreshCircleOutline } from 'react-icons/io5';
import { MdDeleteOutline } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';
import { InsertOrUpdateWorkflowUser } from 'data/workflow/repositories/workflow.repository';
import { EmployeeListAdd, MasterEmployeeReq } from 'data/workflow/schemas/workflow.shema';
import { DmsUserDataReq, UserCompanyList } from 'data/dms/schemas/dms.shema';
import { InsertOrUpdateDmsUser } from 'data/dms/repositories/dms.repository';
import { insertOrUpdateF1User } from 'data/F1Connect/repositories/f1Connect.repostiory';
import { EmployeeF1Request } from 'data/F1Connect/schemas/f1Connect.shema';

interface Option {
    readonly label: string;
    readonly value: string;
}

type Props = {
    isOpen: boolean;
    user?: UserCompany;
    users: UserCompany[];
    onClose: () => void;
    onSubmitEdit: (user: UserCompany) => void;
    onEditSuccess?: (selectedCompany: Option[], selectedEmails: Option[]) => void;
};

function EditUserModal(props: Props) {
    const { isOpen, user, users, onClose, onSubmitEdit, onEditSuccess } = props;
    const screenLoader = useLoader();
    const [selectedUser, setSelectedUser] = useState<UserCompany>();

    useEffect(() => {
        setSelectedUser(user);
    }, [user]);

    const handleChangeSelectedUser = <
        T extends Pick<UserCompany, 'first_name' | 'last_name' | 'email' | 'th_first_name' | 'th_last_name'>,
        K extends keyof T
    >(
        key: K,
        value: T[K]
    ) => {
        setSelectedUser(prevState => {
            if (!prevState) {
                return undefined;
            }

            return {
                ...prevState,
                [key]: value
            };
        });
    };

    const handleSubmitEdit = async () => {
        // When modal is not opened and some user try to submit edit (prevent the hacker!!!)
        if (!selectedUser) {
            return;
        }

        try {
            screenLoader.show();

            if (selectedUser.th_first_name && selectedUser.th_last_name) {
                selectedUser.nationality = "TH"
            }

            await onSubmitEdit(selectedUser);

            fireSwal('Success!', 'แก้ไข User สำเร็จ', 'success').then(() => {
                onClose();
                onEditSuccess && onEditSuccess([], [{ label: '', value: selectedUser.citizen_id }]);
            });
        } catch (error) {
            fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
        } finally {
            screenLoader.hide();
        }
    };

    const fireSwal = useCallback(
        (title: string, message: string, icon: Parameters<typeof Swal.fire>[2]) => {
            return Swal.fire({
                title,
                text: message,
                icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        },
        []
    );

    const handleUpdateUserWorkflow = async (type: 'active' | 'inactive', userMapping: UserCompany[]) => {
        try {
            screenLoader.show();

            const employeeList = userMapping.map(mapUserCompanyToEmployeeListAdd);
            const body: MasterEmployeeReq = {
                empid: userMapping[0].citizen_id,
                status: type,
                userid: userMapping[0].citizen_id,
                EmployeeListAdd: employeeList
            }
            const [, response] = await InsertOrUpdateWorkflowUser(body);

            if (response.message === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Update Success'
                }).then(() => {
                    onClose();
                    onEditSuccess && onEditSuccess([], [{ label: '', value: userMapping[0].citizen_id }]);
                });
            }
        } catch (error) {
            fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
        } finally {
            screenLoader.hide();
        }
    }

    const mapUserCompanyToEmployeeListAdd = (user: UserCompany): EmployeeListAdd => {
        return {
            sid: user.sid,
            empid: user.citizen_id,
            empname: user.first_name,
            firstname: user.first_name,
            lastname: user.last_name,
            companycode: "",
            purchaseplantcode: "",
            divisioncode: "",
            department: "",
            costcenter: "",
            email: user.email,
            phone: "",
            line_id: "",
            position: "",
            sign_type: ""
        };
    };

    const handleUpdateDms = async (type: 'active' | 'inactive', userMapping: UserCompany[]) => {
        try {
            screenLoader.show();

            const employeeList = userMapping.map(mapUserCompanyToUserDmsListAdd);
            const body: DmsUserDataReq = {
                status: type,
                id: userMapping[0].citizen_id,
                list_company: employeeList
            }
            const [, response] = await InsertOrUpdateDmsUser(body);
            if (response === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Update Success'
                }).then(() => {
                    onClose();
                    onEditSuccess && onEditSuccess([], [{ label: '', value: userMapping[0].citizen_id }]);
                });
            }
        } catch (error) {
            fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
        } finally {
            screenLoader.hide();
        }
    }

    const mapUserCompanyToUserDmsListAdd = (user: UserCompany): UserCompanyList => {
        return {
            sid: user.sid,
            role_access: '',
            created_by: '',
            created_on: '',
            updated_by: '',
            updated_on: ''
        };
    };

    const onClickUpdateF1User = async (user: UserCompany, mode: 'multi' | 'one', type: keyof EmployeeF1Request) => {
        try {
            screenLoader.show();
            console.log(selectedUser?.th_first_name);
            const body: EmployeeF1Request = {
                SID: '555',
                CompanyCode: mode === 'multi' ? '' : user.sid,
                Email: type === 'Email' ? user.email : '',
                FirstName: type === 'FirstName' ? user.first_name : '',
                FirstNameTH: selectedUser?.th_first_name ?? '',
                LastName: type === 'LastName' ? user.last_name : '',
                LastNameTH: selectedUser?.th_last_name ?? '',
                UserId: user.citizen_id,
                Username: user.citizen_id
            }

            const [, response] = await insertOrUpdateF1User(body);

            if (response === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Update Success'
                }).then(() => {
                    onClose();
                    onEditSuccess && onEditSuccess([], [{ label: '', value: user.citizen_id }]);
                });
            }
        } catch (error) {
            fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
        } finally {
            screenLoader.hide();
        }
    }

    return (
        <Modal
            size='6xl'
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior='inside'
        >
            <ModalOverlay />
            <ModalContent className='max-w-[100dvw]'>
                <ModalHeader>Edit User</ModalHeader>
                <ModalCloseButton />
                <ModalBody className="flex flex-col gap-2">
                    <div>
                        <Label>User</Label>
                        <Input
                            disabled
                            type="text"
                            placeholder="User"
                            value={selectedUser?.citizen_id ?? ''}
                        />
                    </div>
                    <div>
                        <Label>First Name</Label>
                        <Input
                            type="text"
                            placeholder="First Name"
                            value={selectedUser?.first_name ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('first_name', event.target.value)
                            }
                        />
                    </div>
                    <div>
                        <Label>Last Name</Label>
                        <Input
                            type="text"
                            placeholder="Last Name"
                            value={selectedUser?.last_name ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('last_name', event.target.value)
                            }
                        />
                    </div>
                    <div>
                        <Label>Email</Label>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={selectedUser?.email ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('email', event.target.value)
                            }
                        />
                    </div>
                    <div>
                        <Label>TH First Name</Label>
                        <Input
                            type="text"
                            placeholder="TH First Name"
                            value={selectedUser?.th_first_name ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('th_first_name', event.target.value)
                            }
                        />
                    </div>
                    <div>
                        <Label>TH Last Name</Label>
                        <Input
                            type="text"
                            placeholder="TH Last Name"
                            value={selectedUser?.th_last_name ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('th_last_name', event.target.value)
                            }
                        />
                    </div>
                    <Table >
                        <Thead>
                            <Tr>
                                <Th>No</Th>
                                <Th>Company</Th>
                                <Th>Workflow First Name</Th>
                                <Th>Workflow Last Name</Th>
                                <Th>
                                    Workflow Email
                                </Th>
                                <Th>
                                    <div className='flex flex-row'>
                                        Workflow Sync
                                        <div className='flex flex-row flex-1 justify-end'>
                                            <IoRefreshCircleOutline className='w-6 h-6 align-middle text-blue-600 focus:cursor-grab'
                                                onClick={() => { handleUpdateUserWorkflow('active', users) }}
                                            />
                                            <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600 focus:cursor-grab'
                                                onClick={() => { handleUpdateUserWorkflow('inactive', users) }}
                                            />
                                        </div>
                                    </div>
                                </Th>
                                <Th>Use Workflow</Th>
                                <Th>
                                    <div className='flex flex-row'>
                                        Use DMS
                                        <div className='flex flex-row flex-1 justify-end'>
                                            <IoRefreshCircleOutline className='w-6 h-6 align-middle text-blue-600 focus:cursor-grab'
                                                onClick={() => { handleUpdateDms('active', users) }}
                                            />
                                            <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600 focus:cursor-grab'
                                                onClick={() => { handleUpdateDms('inactive', users) }}
                                            />
                                        </div>
                                    </div>
                                </Th>
                                <Th>F1 Employee Code</Th>
                                <Th>
                                    <div className='flex flex-row'>
                                        F1 First Name
                                        <div className='flex flex-row flex-1 justify-end'>
                                            <IoRefreshCircleOutline
                                                className='w-6 h-6 align-middle text-blue-600'
                                                onClick={() => { onClickUpdateF1User(users[0], 'multi', 'FirstName') }}
                                            />
                                        </div>
                                    </div>
                                </Th>
                                <Th>
                                    <div className='flex flex-row'>
                                        F1 Last Name
                                        <div className='flex flex-row flex-1 justify-end'>
                                            <IoRefreshCircleOutline
                                                className='w-6 h-6 align-middle text-blue-600'
                                                onClick={() => { onClickUpdateF1User(users[0], 'multi', 'LastName') }}
                                            />
                                        </div>
                                    </div>
                                </Th>
                                <Th>
                                    <div className='flex flex-row'>
                                        F1 Email
                                        <div className='flex flex-row flex-1 justify-end'>
                                            <IoRefreshCircleOutline
                                                className='w-6 h-6 align-middle text-blue-600'
                                                onClick={() => { onClickUpdateF1User(users[0], 'multi', 'Email') }}
                                            />
                                            {/* <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600' /> */}
                                        </div>
                                    </div>
                                </Th>
                                {<Th>Active User</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users &&
                                users.map((userMapping, index) => (
                                    <Tr key={userMapping.citizen_id + userMapping.sid}>
                                        <Td>{index + 1}</Td>
                                        <Td>{userMapping.company_name}</Td>
                                        <Td>{userMapping.wf_first_name}</Td>
                                        <Td>{userMapping.wf_last_name}</Td>
                                        <Td>
                                            {userMapping.workflow_email}
                                        </Td>
                                        <Td>
                                            <div className='flex flex-row flex-1 justify-center'>
                                                <IoRefreshCircleOutline className='w-6 h-6 align-middle text-blue-600 focus:cursor-grab'
                                                    onClick={() => {
                                                        handleUpdateUserWorkflow('active', [userMapping])
                                                    }} />
                                                <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600 focus:cursor-grab'
                                                    onClick={() => {
                                                        handleUpdateUserWorkflow('inactive', [userMapping])
                                                    }}
                                                />
                                            </div>
                                        </Td>
                                        <Td>
                                            <div className="flex gap-3">
                                                <label className="relative inline-block h-5 w-14">
                                                    <input
                                                        type="checkbox"
                                                        checked={userMapping.workflow_status === 'true'}
                                                        className="peer/switch invisible h-0 w-0"
                                                    />
                                                    <span
                                                        className={twMerge(
                                                            'pointer-events:none absolute inset-0 cursor-pointer rounded-full bg-gray-100',
                                                            'before:absolute before:top-1/2 before:h-7 before:w-7 before:-translate-y-1/2 before:rounded-full before:bg-white before:shadow-[1px_3px_7px_-2px_rgba(0,0,0,0.75)] before:content-[""]',
                                                            'transition-color before:transition-[transform,_background-color]',
                                                            'peer-checked/switch:bg-primary-100 peer-checked/switch:before:translate-x-7 peer-checked/switch:before:bg-primary-500'
                                                        )}
                                                    />
                                                </label>
                                                <Tag
                                                    className={twMerge(
                                                        'flex-1',
                                                        userMapping.workflow_status === 'true'
                                                            ? 'bg-primary-50 text-primary-900'
                                                            : 'text-gray-600'
                                                    )}
                                                >
                                                    <span className="flex-1 text-center">
                                                        {userMapping.workflow_status === 'true'
                                                            ? 'Active'
                                                            : "InActive"}
                                                    </span>
                                                </Tag>
                                            </div>
                                        </Td>
                                        <Td>
                                            <div className="flex gap-3">
                                                <label className="relative inline-block h-5 w-14">
                                                    <input
                                                        type="checkbox"
                                                        checked={userMapping.dms_status === 'true'}
                                                        className="peer/switch invisible h-0 w-0"
                                                    />
                                                    <span
                                                        className={twMerge(
                                                            'pointer-events:none absolute inset-0 cursor-pointer rounded-full bg-gray-100',
                                                            'before:absolute before:top-1/2 before:h-7 before:w-7 before:-translate-y-1/2 before:rounded-full before:bg-white before:shadow-[1px_3px_7px_-2px_rgba(0,0,0,0.75)] before:content-[""]',
                                                            'transition-color before:transition-[transform,_background-color]',
                                                            'peer-checked/switch:bg-primary-100 peer-checked/switch:before:translate-x-7 peer-checked/switch:before:bg-primary-500'
                                                        )}
                                                    />
                                                </label>
                                                <Tag
                                                    className={twMerge(
                                                        'flex-1',
                                                        userMapping.dms_status === 'true'
                                                            ? 'bg-primary-50 text-primary-900'
                                                            : 'text-gray-600'
                                                    )}
                                                >
                                                    <span className="flex-1 text-center">
                                                        {userMapping.dms_status === 'true'
                                                            ? 'Active'
                                                            : "InActive"}
                                                    </span>
                                                </Tag>
                                                <div className='flex flex-row flex-1 justify-end'>
                                                    <IoRefreshCircleOutline className='w-6 h-6 align-middle text-blue-600'
                                                        onClick={() => { handleUpdateDms('active', [userMapping]) }}
                                                    />
                                                    <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600'
                                                        onClick={() => { handleUpdateDms('inactive', [userMapping]) }}
                                                    />
                                                </div>
                                            </div>
                                        </Td>
                                        <Td>{userMapping.f1_employee_code}</Td>
                                        <Td>
                                            <div className='flex flex-row gap-0.5'>
                                                <span>
                                                    {userMapping.f1_first_name}
                                                </span>
                                                <div className='flex flex-row flex-1 justify-end'>
                                                    <IoRefreshCircleOutline
                                                        className='w-6 h-6 align-middle text-blue-600'
                                                        onClick={() => { onClickUpdateF1User(userMapping, 'one', 'FirstName') }}
                                                    />
                                                    {/* <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600' /> */}
                                                </div>
                                            </div>
                                        </Td>
                                        <Td>
                                            <div className='flex flex-row gap-0.5'>
                                                <span>
                                                    {userMapping.f1_last_name}
                                                </span>
                                                <div className='flex flex-row flex-1 justify-end'>
                                                    <IoRefreshCircleOutline
                                                        className='w-6 h-6 align-middle text-blue-600'
                                                        onClick={() => { onClickUpdateF1User(userMapping, 'one', 'LastNameTH') }}
                                                    />
                                                    {/* <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600' /> */}
                                                </div>
                                            </div>
                                        </Td>
                                        <Td>
                                            <div className='flex flex-row gap-0.5'>
                                                <span>
                                                    {userMapping.f1_email}
                                                </span>
                                                <div className='flex flex-row flex-1 justify-end'>
                                                    <IoRefreshCircleOutline
                                                        className='w-6 h-6 align-middle text-blue-600'
                                                        onClick={() => { onClickUpdateF1User(userMapping, 'one', 'Email') }}
                                                    />
                                                    {/* <MdDeleteOutline className='w-6 h-6 align-middle text-danger-600' /> */}
                                                </div>
                                            </div>
                                        </Td>
                                        <Td>
                                            <div className="flex gap-3">
                                                <label className="relative inline-block h-5 w-14">
                                                    <input
                                                        type="checkbox"
                                                        checked={userMapping.status === 'active'}
                                                        className="peer/switch invisible h-0 w-0"
                                                    />
                                                    <span
                                                        className={twMerge(
                                                            'pointer-events:none absolute inset-0 cursor-pointer rounded-full bg-gray-100',
                                                            'before:absolute before:top-1/2 before:h-7 before:w-7 before:-translate-y-1/2 before:rounded-full before:bg-white before:shadow-[1px_3px_7px_-2px_rgba(0,0,0,0.75)] before:content-[""]',
                                                            'transition-color before:transition-[transform,_background-color]',
                                                            'peer-checked/switch:bg-primary-100 peer-checked/switch:before:translate-x-7 peer-checked/switch:before:bg-primary-500'
                                                        )}
                                                    />
                                                </label>
                                                <Tag
                                                    className={twMerge(
                                                        'flex-1',
                                                        userMapping.status === 'active'
                                                            ? 'bg-primary-50 text-primary-900'
                                                            : 'text-gray-600'
                                                    )}
                                                >
                                                    <span className="flex-1 text-center">
                                                        {userMapping.status === 'pending'
                                                            ? 'Inactive'
                                                            : userMapping.status[0].toUpperCase() +
                                                            userMapping.status.slice(1)}
                                                    </span>
                                                </Tag>
                                            </div>
                                        </Td>
                                    </Tr>
                                ))}
                            {
                                !users && (
                                    <Tr>
                                        <Td
                                            colSpan={13}
                                            className="text-center"
                                        >
                                            No data
                                        </Td>
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>
                </ModalBody>
                <ModalFooter className="gap-4">
                    <Button
                        outline
                        size="sm"
                        className="text-gray-400"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="sm"
                        onClick={handleSubmitEdit}
                        className="bg-primary-900 text-white"
                    >
                        Edit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}

export default EditUserModal;
